:root{
   --color-one: #B5C99A; /* background color --- green */
   --color-two: #6f482b; /* font color --- brown */
   --color-three: #eaa534;
   --color-four:#cd581b;
}

*{
  font-family: 'Heebo';
  color:var(--color-two);

}

body {
  margin: 0;
  font-family: 'Heebo';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--color-one);
}

.App{
  display:grid;
  grid-template-columns: 1fr;
  grid-template-rows: .25fr 1.75fr 2fr .25fr;
}

/* ---------- HEADER ---------- */
.header{
  display:flex;
  justify-content: space-between;
  padding:1em 3em 1em 3em;
  grid-row: 1/2;
  
}

.header button {
  color:var(--color-two);
  background-color: var(--color-one);
  border:none;
  font-size:20px;
}

/*---------- Intro Panel ---------- */

.intro-panel{
  display:grid;
  grid-template-columns: 1fr 1fr;
  margin:auto;
}

.intro-panel-right{
  width:30em;
  
  align-self: center;

}

.intro{
  
  font-style: normal;
  font-weight: 700;
  font-size: 44px;
  line-height: 60px;
}


.intro-sub{
  width: 497px;
  height: 66px;
  left: 15%;
  top: 373px;

  
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px; 

  font-weight: bold;

}

.intro-resume-button{
  width: 208px;
  height: 47px;
  left: 15%;
  top: 477px;
  background: #f8e3ba;

  border-radius: 10px;

  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 29px;

}

.info-panel-photo{

  width: 350px;
  height: 350px;
  border:1px solid var(--color-two);
  border-radius: 100%;
 
}


 /* ---------- About Section ----------- */
 .about{
  margin-bottom: 5%;
 }
.about-title{
  font-size: 2.5em;
}

.about-info{
  font-size: 1.5em;
  margin: 0 15% 5% 15%;
}

/* ---------- SKILLS ----------- */

.skills{
  display:grid;
  grid-template-columns: 1fr 1fr 1fr;
  margin:0 20% 0 20%
}

.skill-type{
  display:flex;
  flex-direction: column;
  justify-content: top;
}

.skills h3{
  text-decoration: underline;
}

.skills p{
  margin: -.005em;
  padding: none;
}

/* ----------CArousel---------- */

.carousel{
  height:400px;
}

/* ----------Project Card ---------- */

.project-title{
  font-size:2.5rem;
}

.projectCard{
  display:grid;
  grid-template-columns: 2fr 1fr;
  background: #f8e3ba;
  font-weight: bold;
  color:var(--color-two)
}

.project-description{
  display:flex;
  flex-direction: column;
  justify-content: space-between;
}
.projectCard-title{
  font-size:40px;
}

.project-button{
  background-color:var(--color-three);
  border:none;
}

.project-button a{
  text-decoration: none;
  color:var(--color-two);
}


/* ---------- CONTACT ---------- */

.contact h2{
  font-size: 2.5rem;
}

.contact p {
  font-size:1.5rem;
}

.social-links{
  font-size:30px;
  display:flex;
  justify-content: center;
  align-items: center;
  gap:40px;
}


.resume-page{
  text-align: center;
}